import './application.js'
import '../stylesheets/application.scss'
import '../stylesheets/etyekimenhely.scss'
import magnificPopup from 'magnific-popup';
import '../../../vendor/layerslider/js/greensock.js'
import '../../../vendor/layerslider/js/layerslider.transitions.js'
import '../../../vendor/layerslider/js/layerslider.kreaturamedia.jquery.js'
import '../../../vendor/layerslider/js/layerslider.load.js'

window.magnificPopup = magnificPopup;

var Isotope = require('isotope-layout');
var AOS = require('aos');
var L = require('leaflet');

/*
Template Name: Woof!
Author: Ingrid Kuhn
Author URI: themeforest/user/ingridk
Version: 1.0
*/
jQuery(function ($) {

  //----------------------------------- Document ready -----------------------------------//

  $(document).ready(function () {

    //Effects on scroll
    AOS.init({
      disable: 'mobile',
      duration: 1500,
      once: true
    });

    //Scrolling feature

    $('.page-scroll a').on('click', function (event) {
      var $anchor = $(this);
      $('html, body').stop().animate({
        scrollTop: $($anchor.attr('href')).offset().top
      }, 1500, 'easeInOutExpo');
      event.preventDefault();
    });

    //Dropdown on hover

    if ($(window).width() >= 1024) {
      $(".navbar .dropdown").on({
        mouseenter: function () {
          $(this).find('.dropdown-menu').first().stop(true, true).delay(50).slideDown();

        },
        mouseleave: function () {
          $(this).find('.dropdown-menu').first().stop(true, true).delay(200).slideUp();
        }
      });
    }

    //	Back Top Link

    var offset = 200;
    var duration = 500;
    $(window).scroll(function () {
      if ($(this).scrollTop() > offset) {
        $('.back-to-top').fadeIn(400);
      } else {
        $('.back-to-top').fadeOut(400);
      }
    });


    // Magnific Popup

    $('#gallery-isotope').magnificPopup({
      delegate: 'a', // child items selector, by clicking on it popup will open
      type: 'image',
      gallery: {
        enabled: true
      },
      titleSrc: 'title',
      titleSrc: function (item) {
        return '<a href="' + item.el.attr('href') + '">' + item.el.attr('title') + '</a>';
      }
    });




    //------- Window scroll function -------//
    $(window).scroll(function () {

      //Collapse the top bar on scroll

      if ($("#main-nav").offset().top > 60) {
        $('.top-bar').slideUp({
          duration: 250,
          easing: "easeInOutSine"
        }).fadeOut(120);
      } else {
        $('.top-bar').slideDown({
          duration: 0,
          easing: "easeInOutSine"
        }).fadeIn(120);
      }



    }); // end window scroll

  }); // end document ready


  //----------------------------------- Window load function -----------------------------------//

  $(window).on('load', function () {

    if ($('#gallery-isotope').length) {
      var container = new Isotope('#gallery-isotope', {
        filter: '*',
        animationOptions: {
          duration: 750,
          easing: 'linear',
          queue: false,
          layoutMode: 'masonry'
        }
      });
    }

  }); // end window load function

}); // end jquery function